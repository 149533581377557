function SwitchToGallery(evt) {
	$('.SubmitPhoto').removeClass('visible');
	$('.PreviewPhoto').addClass('visible');
}

var InfoBox = {
	info: {},
	menubox: {},
	focusableElementsString: 'a, a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [contenteditable]',
	init: function () {
		console.log('init');
		if ($(".reportMenu").length > 0) {
			this.info = document.querySelector(".infoBox");
			this.menubox = document.querySelector(".reportMenu");

			var menuElements = InfoBox.info.querySelectorAll(InfoBox.focusableElementsString);
			menuElements.forEach(function(element){
				element.setAttribute('tabindex', "-1");
			});
		}
	},

	OpenInfoBox: function (evt) {
		console.log('opening');
		evt.preventDefault();
		if ($(".reportMenu").hasClass("open")) {
			InfoBox.CloseInfoBox();
		} else {
			$('.reportMenu').addClass("open");
			$('.infoBox').addClass("open");
			$('.infoSlider').attr("title", "Sluit het menu");

			var focusableElements = document.querySelector('section.content').querySelectorAll(InfoBox.focusableElementsString);
			focusableElements.forEach(function(element){
				element.setAttribute('tabindex', "-1");
			});

			var menuElements = InfoBox.info.querySelectorAll(InfoBox.focusableElementsString);
			menuElements.forEach(function(element){
				element.setAttribute('tabindex', "0");
			});
		}


		InfoBox.SetInfoBoxSubject(evt);
	},

	CloseInfoBox: function (evt) {
		$('.reportMenu').removeClass("open");
		$('.infoBox').removeClass("open");
		$('.infoSlider').attr("title", "Open het menu");

		var focusableElements = document.querySelector('section.content').querySelectorAll(InfoBox.focusableElementsString);
		focusableElements.forEach(function(element){
			element.setAttribute('tabindex', "0");
		});

		var menuElements = InfoBox.info.querySelectorAll(InfoBox.focusableElementsString);
		menuElements.forEach(function(element){
			element.setAttribute('tabindex', "-1");
		});


		InfoBox.ResetInfoBoxMenu();
	},

	SetInfoBoxSubject: function (evt) {
		InfoBox.ResetInfoBoxMenu();
		if ($('.infoBox').find('.' + $(evt.currentTarget).data("id")).length > 0) {
			$('.backToMainMenu').attr("data-id", $(evt.currentTarget).data("id"));

			$('.infoBox').attr("data-id", $(evt.currentTarget).data("id"));
			$('.infoBox').find('.' + $(evt.currentTarget).data("id")).addClass("visible");


			InfoBox.checkSiblingSubMenu();
		}
	},

	ResetInfoBoxMenu: function () {
		$('.infoBox').removeAttr("data-id");
		$('.backToMainMenu').removeAttr("data-id");
		$('.infoBox .subMenu').removeClass("visible");
	},

	MoveToNextSubMenu: function (evt) {
		evt.preventDefault();
		var currentActive = document.querySelector('.infoBox .subMenu.visible');
		$(currentActive.nextElementSibling).addClass("visible");
		$(currentActive).removeClass("visible");
		InfoBox.checkSiblingSubMenu();
	},

	MoveToPreviousSubMenu: function (evt) {
		evt.preventDefault();
		var currentActive = document.querySelector('.infoBox .subMenu.visible');
		$(currentActive.previousElementSibling).addClass("visible");
		$(currentActive).removeClass("visible");
		InfoBox.checkSiblingSubMenu();
	},

	checkSiblingSubMenu: function () {
		var currentActive = document.querySelector('.infoBox .subMenu.visible');
		if (currentActive != undefined) {
			if ($(currentActive.previousElementSibling).hasClass("subMenu")) {
				$('.infoBoxPager .previous').addClass("visible");
			} else {
				$('.infoBoxPager .previous').removeClass("visible");
			}

			if ($(currentActive.nextElementSibling).hasClass("subMenu")) {
				$('.infoBoxPager .next').addClass("visible");
			} else {
				$('.infoBoxPager .next').removeClass("visible");
			}
		} else {
			$('.infoBoxPager .previous').removeClass("visible");
			$('.infoBoxPager .next').removeClass("visible");
		}
	}
}

var Maps = {
	map: {},
	currentLatLng: {},
	currentMarker: {},
	init: function () {
		if ($('#map').length > 0) {
			Maps.initMap();
			if (location.protocol === 'https:') {
				Maps.GetCurrentUserLocation();
			} else {
				Maps.GeoStopped();
			}
		}
	},

	initMap: function () {
		Maps.currentLatLng = new google.maps.LatLng(51.570003, 4.029191);

		if ($("#locationOutput").val()) {
			var latlngOut = $('#locationOutput').val().replace(/[()]/g, '').split(',')
			latlngOut = new google.maps.LatLng(latlngOut[0], latlngOut[1])

			Maps.currentLatLng = latlngOut;
		}

		var mapOptions = {
			center: Maps.currentLatLng,
			zoom: 15,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			disableDefaultUI: true,
			clickableIcons: false,
		};
		Maps.map = new google.maps.Map(document.getElementById('map'), mapOptions);
		var zoomControlDiv = document.createElement('div');
		var zoomControl = new Maps.ZoomControl(zoomControlDiv, map);

		zoomControlDiv.index = 1;
		Maps.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlDiv);

		google.maps.event.addDomListener(Maps.map, 'click', function (e) {
			Maps.PlaceMarker(e.latLng, Maps.map);
		});
	},

	GetCurrentUserLocation: function () {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(Maps.GeoSuccess, Maps.GeoStopped);
		} else {
			console.log("current geolocation not supported");
		}
	},

	GeoSuccess: function (position) {
		$('.loader').removeClass("visible");
		Maps.currentLatLng = position;
		Maps.PlaceMarker(latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude), Maps.map);
	},

	GeoStopped: function () {
		$('.loader').removeClass("visible");
		Maps.PlaceMarker(Maps.currentLatLng, Maps.map);
	},
	PlaceMarker: function (latLng, map) {
		if ($.isEmptyObject(Maps.currentMarker)) {
			Maps.currentMarker = new google.maps.Marker({
				position: latLng,
				draggable: true,
				map: map
			});

			google.maps.event.addListener(Maps.currentMarker, 'dragend', function (ev) {
				Maps.UpdateLocationInformation(Maps.currentMarker.getPosition());
			})
		} else {
			Maps.currentMarker.setPosition(latLng);
		}
		Maps.currentLatLng = latLng;
		map.panTo(latLng);
		Maps.UpdateLocationInformation(latLng);
	},

	UpdateLocationInformation: function (latLng) {
		$("#locationOutput").val(latLng);
		var googleGeocode = new google.maps.Geocoder();
		googleGeocode.geocode({
			'latLng': latLng
		}, function (results, status) {
			if (status == google.maps.GeocoderStatus.OK && results[0]) {
				var locInfo = results[0].formatted_address.split(',')

				var locationText = locInfo[0];
				locationText += "<br />" + locInfo[1];
				locationText += "<br />" + latLng.lat() + ", " + latLng.lng()
				$('.mapInformation').html(locationText);
			}
		});

	},

	ZoomControl: function (controlDiv, map) {

		// Creating divs & styles for custom zoom control

		// Set CSS for the control wrapper
		var controlWrapper = document.createElement('div');
		controlWrapper.style.cursor = 'pointer';
		controlWrapper.style.position = 'relative';
		controlWrapper.style.bottom = '10px';
		controlDiv.appendChild(controlWrapper);

		// Set CSS for the zoomOut
		var zoomOutButton = document.createElement('div');
		zoomOutButton.style.width = '50px';
		zoomOutButton.style.height = '50px';
		zoomOutButton.style.backgroundColor = 'white';
		zoomOutButton.style.display = 'inline-block';
		/* Change this to be the .png image you want to use */
		controlWrapper.appendChild(zoomOutButton);

		// Set CSS for the zoomIn
		var zoomInButton = document.createElement('div');
		zoomInButton.style.width = '50px';
		zoomInButton.style.height = '50px';
		zoomInButton.style.backgroundColor = 'white';
		zoomInButton.style.display = 'inline-block';
		/* Change this to be the .png image you want to use */
		controlWrapper.appendChild(zoomInButton);

		// Setup the click event listener - zoomIn
		google.maps.event.addDomListener(zoomInButton, 'click', function () {
			Maps.map.setZoom(Maps.map.getZoom() + 1);
		});

		// Setup the click event listener - zoomOut
		google.maps.event.addDomListener(zoomOutButton, 'click', function () {
			Maps.map.setZoom(Maps.map.getZoom() - 1);
		});

	}


}
var validationErrors = false;

var forms = {
	init: function () {
		forms.validation.listeners();

		$('body').on('click', ".menuButton.closebtn", InfoBox.CloseInfoBox);

		//$('body').on('click', '.subMenuLink', SetInfoBoxSubject($(this).data('id')));

		forms.setStep();
		experienceGallery.init();
	},

	setStep: function () {
		var step = $('.RegistrationPointForm form').data('step');

		if (step != null) {
			var strokeOffset = (113 / 6) * (6 - step);
			//console.log(strokeOffset);
			$('.icon.indicator').css({
				'strokeDashoffset': strokeOffset
			});
			$('.indicatorText').html(step + " / 6");
		}
	},

	openSubDialog(evt) {
		$('.FindLocation').removeClass('visible');
		$('.EnterLocation').addClass('visible');
		$('.subdialog.' + $(evt.target).data("subdialog") + '').addClass("visible");
		$('#locationTypeChoice').val($(evt.target).data("subdialog"));
	},

	validation: {
		listeners: function () {

			$('body').on('blur change paste keyup', 'input', function () {
				forms.validation.inputRules($(this));
				forms.validation.checkEmptyField();
			});

			$('body').on('blur change paste keyup', 'input', function () {
				forms.validation.inputRules($(this));
				forms.validation.checkEmptyField();
			});

			$('body').on('blur change paste keyup', 'textarea', function () {
				forms.validation.checkRequired($(this));
				forms.validation.checkEmptyField();
			});

			$('body').on('change change paste keyup', 'select', function () {
				forms.validation.checkRequired($(this));
				forms.validation.checkEmptyField();
			});
		},

		checkEmptyField: function (element) {
			$('.formStep.currentStep input[required="required"]').each(function () {
				if ($(this).val() === '') {
					$('.formStep.currentStep button[type="submit"]').prop('disabled', true);
				}
			});
		},

		setValid: function (element) {
			element.closest('.fieldRow').removeClass('error');
			element.closest('.selectRow').removeClass('error');
			$('.formStep.currentStep button[type="submit"]').prop('disabled', false);
		},

		setInvalid: function (element) {
			element.closest('.fieldRow').addClass('error');
			element.closest('.selectRow').addClass('error');
			$('.formStep.currentStep button[type="submit"]').prop('disabled', 'disabled');
			validationErrors = true;
		},

		executeAll: function (parentForm) {
			validationErrors = false;

			parentForm.find('input').each(function () {
				forms.validation.inputRules($(this));
			});

			parentForm.find('textarea').each(function () {
				forms.validation.checkRequired($(this));
			});

			parentForm.find('select').each(function () {
				forms.validation.checkRequired($(this));
			});

			parentForm.find('.inputHolder.checkbox.required').each(function (element) {
				forms.validation.checkRequiredCheckboxes($(this));
			});
		},

		inputRules: function (element) {
			forms.validation.checkRequired(element);
			forms.validation.checkValidMail(element);
		},

		checkRequired: function (element) {
			if (!element.val() && element[0].required == true) {
				forms.validation.setInvalid(element);
			} else {
				forms.validation.setValid(element);
			}
		},

		checkRequiredCheckboxes: function (element) {
			if (element.find(':checkbox:checked').length == 0) {
				forms.validation.setInvalid(element);
			} else {
				forms.validation.setValid(element);
			}
		},

		checkValidMail: function (element) {
			if (element.hasClass('email')) {
				var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				var input = element.val();
				if (regex.test(input)) {
					forms.validation.setValid(element);
				} else {
					forms.validation.setInvalid(element);
				}
			}
		},

		getvalidationMessages: function () {
			$('.field-validation-valid').each(function () {
				var message = $(this).parent().find('input').data('val-required');
				$(this).text(message).removeClass('field-validation-valid').addClass('errorMessage');
			});
		},

		maskDates: function () {
			//$('input#Birthdate').mask('00-00-0000');
			//$('input#CareStartDate').mask('00-00-0000');
			if ($('input#Birthdate').length || $('input#CareStartDate').length) {
				var dateToday = new Date(); // Set today's date
				if (window.windowWidth < 667) {
					$('input#Birthdate').attr('type', 'date');
					$('input#CareStartDate').attr('type', 'date');
				} else {
					$('input#Birthdate').datepicker({
						onSelect: function (dateText) {
							forms.validation.checkRequired($(this));
							forms.validation.checkEmptyField();
						},
						dateFormat: 'dd-mm-yy',
						changeYear: true,
						yearRange: '-70:+0'
					});
					$('input#CareStartDate').datepicker({
						onSelect: function (dateText) {
							forms.validation.checkRequired($(this));
							forms.validation.checkEmptyField();
						},
						dateFormat: 'dd-mm-yy',
						minDate: dateToday
					});
				}
			}
		}
	},

	loadAjax: function (url) {
		$.get(url, function (data) {
			//success
			$('.formPopup').removeClass('loading');
			$('.formPopup .inner .scrollWrap .content').html(data);
			$('.conditionalDropdown select').each(function () {
				forms.conditionalDropdown($(this));
			});
			forms.validation.getvalidationMessages();
			forms.validation.maskDates();
		});
	},

	nextStep: function (data) {

		var dataObj = $(data);

		var targetId = dataObj.find('form').attr('id');
		var currentId = $('.RegistrationPointForm form').attr('id');

		var targetContent = $('.RegistrationPointForm');

		setTimeout(function () {

			if (targetId != currentId) {}

			targetContent.html(dataObj);

			forms.setStep();

			forms.validation.getvalidationMessages();
		}, 200);

	},

	submitAjax: function () {
		var targetContent = $('.RegistrationPointForm');
		var url = targetContent.find("form").attr('action');
		$.ajax({
			type: "POST",
			url: url,
			data: targetContent.find("form").serialize(),
			success: function (data) {
				forms.nextStep(data);
			},
			complete: function () {

			}
		});
	},
};

var experienceGallery = {
	galleryAddButton: {},
	init: function () {
		experienceGallery.onPageLoad();
		experienceGallery.onChange();
		experienceGallery.PreviewHandler();
	},

	onPageLoad: function () {



		if ($('.currentImages li').length > 1) {
			SwitchToGallery();
		}
	},

	PreviewHandler: function () {
		$('body').on('click', 'img.thumb', function (e) {
			$('.imagePreview .imageHolder').html($(e.target).clone());
			$('.imagePreview').addClass('open');
			slimmageTools.reload();
		});

		$('body').on('click', '.imagePreview #close', function (e) {
			$('.imagePreview').removeClass('open');
		});

		$('body').on('click', '.imagePreview #remove', experienceGallery.removals);
	},

	loadImage: function (file, callback) {
		var reader = new FileReader();

		reader.onload = function (event) {
			var img = new Image();

			img.onload = function () {
				callback(img);
			};

			img.src = event.target.result;
		};

		reader.onerror = function (error) {
			console.error('Error reading the file:', error);
		};

		reader.readAsDataURL(file);
	},

	onChange: function () {

		$('body').on('change', 'input.gallery', function (e) {
			var filename = "";

			var fileArray = [];
			if (e.target) {
				fileArray = e.target.files;
			} else {
				fileArray = e.originalEvent.srcElement.files;
			}
			for (var i = 0; i < fileArray.length; i++) {

				var file = fileArray[i];
				filename = file.name;
				var img = document.createElement('img');
				var reader = new FileReader();

				experienceGallery.loadImage(e.target.files[0], function (img) {
					var canvas = document.createElement('canvas');
					var maxWidth = 1280;
					var maxHeight = 1280;
					var width = img.width;
					var height = img.height;

					if (width > height) {
						if (width > maxWidth) {
							height *= maxWidth / width;
							width = maxWidth;
						}
					} else {
						if (height > maxHeight) {
							width *= maxHeight / height;
							height = maxHeight;
						}
					}

					canvas.width = width;
					canvas.height = height;

					var ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, width, height);

					var dataUrl = canvas.toDataURL("image/jpeg", 1);
					var index = experienceGallery.checkImgIndex();
					experienceGallery.placeImg(dataUrl, index, filename);
					experienceGallery.checkAllowedToPlace();
				}, {
					orientation: true
				});
			}
		});
	},

	removals: function (e) {
		var preview = $(e.target).closest('.imagePreview').find('img.thumb');
		e.preventDefault();
		//console.log(preview.data("number"));
		if (typeof preview.data("number") != "undefined") {
			$('.currentImages li[data-number="' + preview.data("number") + '"').remove();
			preview.remove();
			$('.imagePreview').removeClass("open");
			experienceGallery.checkAllowedToPlace();
		}
	},

	checkImgIndex: function (img) {
		for (i = 1; i <= 6; i++) {
			if ($('.currentImages li[data-number="' + i + '"]').length == 0) {
				return i;
			}
		}
	},

	placeImg: function (img, index, filename) {

		var valueObject = {
			name: filename,
			type: "image/jpg",
			image: img
		}

		var html = '' +
			'<li data-number="' + index + '">' +
			'<img data-number="' + index + '" class="thumb" src="' + img + '" alt="">' +
			'<a class="removeLink" target="blank">-</a>' +
			'<input type="hidden" name="galleryImage" value="' + JSON.stringify(valueObject).replace(/\"/g, '&quot;') + '">' +
			'</li>';
		experienceGallery.galleryAddButton = $('.currentImages ul li[data-id="plus"]');
		$('.currentImages ul li[data-id="plus"]').replaceWith(html);
	},

	checkAllowedToPlace: function () {
		if ($('.currentImages li').length != 6) {
			$('.currentImages ul').append(experienceGallery.galleryAddButton);
		}
	}
};

$(function () {
	forms.init();
	Maps.init();
	InfoBox.init();

	$('body').on('click', '.infoSlider', InfoBox.OpenInfoBox);

	$('body').on('click', '.infoBoxPager .previous', InfoBox.MoveToPreviousSubMenu);

	$('body').on('click', '.infoBoxPager .next', InfoBox.MoveToNextSubMenu);

	$('body').on('click', '.backToMainMenu', InfoBox.ResetInfoBoxMenu);

	$('body').on('click', '.infoLink', InfoBox.OpenInfoBox);

	$('body').on('click', '.infoIconLink', InfoBox.OpenInfoBox);

	$('body').on('click', ".infoSubLink", InfoBox.SetInfoBoxSubject);

	$('body').on('change', 'input.gallery', SwitchToGallery);
})